<template>
  <div class="breadcrumbs">
    <span class="home">
      <img src="@/assets/images/icon_home.png" width="14" height="12" alt="" />
    </span>
    home
    <span class="breadcrumb" v-html="getBreadcrumb()"> </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from '@/store/index';

export default defineComponent({
  setup() {
    const store = useStore();
    const route = useRoute();

    const defaultPaths = route.path
      .split('/')
      .filter(p => !!p)
      .map(p => `/${p}`);

    const defaultResources = store.state.resources;

    const getBreadcrumb = (paths = defaultPaths, resources = defaultResources): string => {
      const validator = () => paths.length && resources;
      if (validator()) {
        const path = paths.shift();
        const resource = Object.values(resources).find(r => r.path === path);
        if (resource) {
          return `<span>${resource.name}</span>${validator() ? getBreadcrumb(paths, resource.subPath) : ''}`;
        }
      }
      return '';
    };

    const methods = { getBreadcrumb };

    return {
      ...methods
    };
  }
});
</script>

<style scoped lang="scss">
@import './Breadcrumb.scss';
</style>
