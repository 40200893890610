<template>
  <div class="content_toparea_wrapper">
    <div class="content_toparea">
      <breadcrumb></breadcrumb>
      <share></share>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Breadcrumb from './Breadcrumb.vue';
import Share from './Share.vue';

export default defineComponent({
  components: { Breadcrumb, Share }
});
</script>

<style scoped lang="scss">
@import './BreadcrumbWrapper.scss';
</style>
