const shareTwitter = (
  text: string,
  features = {
    width: 450,
    height: 550,
    resizable: 'no'
  }
): void => {
  const url = 'https://twitter.com/intent/tweet';
  const queryString = `url=${location.href}&text=${encodeURIComponent(text)}`;
  const { width, height, resizable } = features;
  window.open(`${url}?=${queryString}`, 'share_twitter', `width=${width},height=${height},resizable=${resizable}`);
};

export { shareTwitter };
