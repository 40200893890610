
import { defineComponent } from 'vue';
import { shareFacebook } from '@/api/facebook';
import { shareTwitter } from '@/api/twitter';

export default defineComponent({
  setup() {
    const print = () => window.print();

    const copyUrl = () => {
      const appName = navigator.appName;
      const userAgent = navigator.userAgent.toLowerCase();

      if ((appName === 'Netscape' && userAgent.includes('Trident')) || userAgent.includes('msie')) {
        window.clipboardData.setData('Text', location.href);
        alert('URL이 복사되었습니다.<br/>Ctrl+V로 URL을 입력할 수 있습니다.');
      } else {
        prompt('Ctrl+C를 눌러 클립보드로 복사하세요.\nCtrl+V로 URL을 입력할 수 있습니다.', location.href);
      }
    };

    const methods = { shareFacebook, shareTwitter, print, copyUrl };

    return { ...methods };
  }
});
